import React, { useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@mui/material/Typography';
import Paper from '@material-ui/core/Paper'
import { useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Password, Username } from '../../components/authComponents'
import { AuthContext } from '../../contexts/authContext'
import { useTranslation } from 'react-i18next'
import frFlag from '../../locales/fr/flag.png';
import enFlag from '../../locales/en/flag.png';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import bgF2m from '../../ressources/backgroundf2m.png';
import f2mlogo from '../../ressources/f2m_logo.png'
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import { theme } from '../../libs/theme'


const useStyles = makeStyles({
  root: {
    height: '100vh',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: '100% auto',
    backgroundColor: "#08100a",

  },
  topR: {
    position: "absolute",
    marginTop: "20px",
    marginRight: "20px",
    top: 0,
    right: 0
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '2.0rem!important',
  },
  titleIntro: {
    color: "white",
    fontSize: '2.0rem!important',
  },
  textIntro: {
    color: "white",
    fontSize: '1.3rem!important',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
})

const SignIn: React.FunctionComponent<{}> = () => {
  const classes = useStyles()
  const userName = new URLSearchParams(useLocation().search).get("userName")
  const { t, i18n } = useTranslation();
  const { username, setUsername, usernameIsValid } = useValidUsername(userName || '')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')
  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0
  const history = useHistory()
  const authContext = useContext(AuthContext)
  const signInClicked = async () => {
    try {
      await authContext.signInWithEmail(username, password)
      history.push('home')
    } catch (err: any) {
      console.log(JSON.stringify(err.message))
      console.log(err.code)
      switch (err.code) {
        case 'UserNotConfirmedException':
          history.push('verify')
          break;
        case 'UserNotInitException':
          history.push(`createpassword?userName=${username}`)
          break;
        case 'NotAuthorizedException':
          if (err.message === 'User is disabled.') {
            setError(t("userDisabledError"))
            break;
          }
          setError(t("invalidLogin"))
          break;
        case 'UserNotFoundException':
          setError(t("invalidLogin"))
          break;
        case 'LimitExceededException':
          setError(t("limitExceeded"))
          break;
        case 'NetworkError':
          setError(t("networkError"))
          break;
        case 'UserDisabled':
          setError(t("userDisabledError"))
          break;
        default:
          setError(t("genericError"))

      }

    }
  }

  const passwordResetClicked = async () => {
    history.push('requestcode')
  }

  const goToSignup = async () => {
    history.push('signup')
  }
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };


  return (
    <>

      <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
        <Box className={classes.topR}>
          {
            /*<Button variant="contained" color="primary" onClick={goToSignup}>
            {t('signupConfirm')}
            </Button>*/
          }
        </Box>
        <Box sx={{
          display: 'inline-flex',
          top: '20px',
          marginLeft:'25%',
          position: 'absolute',
          color: 'white'
        }}>
          <img
            src={f2mlogo}
            height={'90px'}
          />
          {/*<ElectricCarIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />*/}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/#/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
          </Typography>
        </Box>
        <Grid xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
          <Paper style={{ width: '25%', padding: 32, left: 0, position: "absolute", height: "100%" }}>
            <Grid container direction="row" justify="center" >
              <Grid item xs={1} alignItems="center">
              </Grid>
              <Grid item xs={8} alignItems="center">
                <Typography className={classes.title}>{t("login")}</Typography>
              </Grid>
              <Grid item xs={3} style={{ right: '10px' }}>
                <IconButton onClick={e => { changeLanguage('fr') }} sx={{ p: 0 }}>
                  <Avatar alt="Francais" src={frFlag} sx={{ height: '30px', width: '30px' }} />
                </IconButton>
                <IconButton onClick={e => { changeLanguage('en') }} sx={{ p: 1 }}>
                  <Avatar alt="English" src={enFlag} sx={{ height: '30px', width: '30px' }} />
                </IconButton>
              </Grid>
              <Grid item xs={1} alignItems="center">
              </Grid>
              <Grid item xs={8} alignItems="center">
                <br />
                <Typography color="error">
                  {error}
                </Typography>
                <br />
              </Grid>
              <Grid item xs={3} style={{ right: '10px' }}>

              </Grid>
              <Box width="80%" m={1}>
                <Typography color="error">
                  
                </Typography>
                <br />
                <Username
                  initialValue={username}
                  usernameIsValid={usernameIsValid}
                  setUsername={setUsername} />
              </Box>
              <Box width="80%" m={1}>
                <Password
                  label="Password"
                  errorLabel="passwordPolicy"
                  passwordIsValid={passwordIsValid}
                  setPassword={setPassword} />
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Box onClick={passwordResetClicked} mt={2}>
                    <Typography className={classes.hover} variant="body2">
                      {t('forgotPassword')} ?
                    </Typography>
                  </Box>
                </Grid>
              </Box>

              {/* Error */}


              {/* Buttons */}
              <Box mt={2}>
                <Grid container direction="row" justify="center">

                  <Box m={1}>
                    <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
                      {t("cancel")}
                    </Button>
                  </Box>
                  <Box m={1}>
                    <Button disabled={isValid} color="primary" variant="contained" onClick={signInClicked}>
                      Login
                    </Button>
                  </Box>
                </Grid>

                <Grid container direction="row" justify="flex-start" >

                </Grid>



              </Box>
              <Box width="80%" m={1}>
                <hr style={{ color: "darkblue" }} />
                <Grid item xs={1} alignItems="center">
                </Grid>
                <Grid item xs={8} alignItems="center">
                  <Typography variant="body2">{t("firstlogin")}</Typography>
                </Grid>
                <Grid item xs={3} style={{ right: '10px' }}>

                </Grid>
                <Box mt={2}>
                  <Grid container direction="row" justify="center">

                    <Box m={1}>
                      <Button variant="contained" color="primary" onClick={goToSignup}>
                        {t('signupConfirm')}
                      </Button>
                    </Box>

                  </Grid>

                  <Grid container direction="row" justify="flex-start" >
                  </Grid>
                </Box>
              </Box>
            </Grid>


          </Paper>
          <Box style={{ width: '75%', padding: 32, right: 0, top: 100, position: "absolute", height: "100%",overflow:'auto', display: 'block' }}>
            <Typography className={classes.titleIntro}>{t("Information")}</Typography>
            <br />
            <Typography className={classes.textIntro}>
              Haylectric restera accessible jusqu'en Fevrier pour récuperer les données et finaliser les notes de frais.
              </Typography>
            <br />
            <Typography className={classes.textIntro}>
              N'hésitez pas si vous constater un disfonctionnement a contacter le support.
              Merci.
            </Typography>
          </Box>
        </Grid>
      </Grid >
    </>
  )
}

export default SignIn
