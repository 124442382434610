import React, { useState, useContext } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import Paper from '@material-ui/core/Paper'
import Typography from '@mui/material/Typography';
import { useValidCode, useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Code, Password, Username } from '../../components/authComponents'
import { AuthContext } from '../../contexts/authContext'

import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import { useTranslation } from 'react-i18next'
import bgF2m from '../../ressources/backgroundf2m.png';
import { theme } from '../../libs/theme'
import frFlag from '../../locales/fr/flag.png';
import enFlag from '../../locales/en/flag.png';
import i18n from '../../libs/i18n'
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles({
  root: {
    overflow: 'auto',
    height: '100vh',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '2.0rem!important',
  },
  text: {
    textAlign: 'left',
  },
})

export default function ForgotPassword() {
  const userName = new URLSearchParams(useLocation().search).get("userName")
  const classes = useStyles()
  const { code, setCode, codeIsValid } = useValidCode('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const { username, setUsername, usernameIsValid } = useValidUsername(userName? userName : '')
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !usernameIsValid ||
    username.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const resetPassword = async () => {
    try {
      await authContext.forgotPassword(username, code, password)
      setReset(true)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
      </Box>
      <Box width="80%" m={1}>
        <Username 
        initialValue={username}
        usernameIsValid={usernameIsValid} 
        setUsername={setUsername} />
      </Box>
      <Box width="80%" m={1}>
        <Password 
        label="Password" 
        errorLabel='passwordPolicy'
        passwordIsValid={passwordIsValid} 
        setPassword={setPassword} />
      </Box>
      <Box width="80%" m={1}>
        <Password 
        errorLabel='passworddontMatch'
        label="passwordConfirm"
         passwordIsValid={passwordConfirmIsValid} 
         setPassword={setPasswordConfirm} />
      </Box>

      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button onClick={() => history.goBack()} color="secondary" variant="contained">
              {t('cancel')}
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid} color="primary" variant="contained" onClick={resetPassword}>
             {t("create")}
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  const passWordCreationConfirm
   = (
    <>
      <Box width="80%" >
        <br />
        <Typography className={classes.text} variant="h6">
          {t('passwordResetSucess')}
        </Typography>
      <Typography className={classes.text} variant="h6">
        {t('youCanUseYourNewPassword')}
      </Typography>
      <br />
    </Box>
      <Box mt={4}>
        <Button onClick={() => history.push('/signin')} color="primary" variant="contained">
        {t("goTologin")}
        </Button>
      </Box>
    </>
  )
  return(
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
    <Box sx={{
      display: 'inline-flex',
      top: '20px',
      position: 'absolute',
      color: 'white'
    }}>
      <ElectricCarIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
      <Typography
        variant="h6"
        noWrap
        component="a"
        href="/#/"
        sx={{
          mr: 2,
          display: { xs: 'none', md: 'flex' },
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
      </Typography>
    </Box>
    <Grid xs={12} sm={6} lg={5} container direction="row" justify="center" alignItems="center" item>
      <Paper style={{ width: '100%', padding: 32 }}>
        <Grid container direction="row" justify="center" >
          <Grid item xs={1} alignItems="center">

          </Grid>
          <Grid item xs={8} alignItems="center">
            <Typography className={classes.title}>{t("createPassword")}</Typography>
          </Grid>
          <Grid item xs={2} style={{ right: '10px' }}>
            <IconButton onClick={e => { changeLanguage('fr') }} sx={{ p: 0 }}>
              <Avatar alt="Francais" src={frFlag} sx={{ height: '30px', width: '30px' }} />
            </IconButton>
            <IconButton onClick={e => { changeLanguage('en') }} sx={{ p: 1 }}>
              <Avatar alt="English" src={enFlag} sx={{ height: '30px', width: '30px' }} />
            </IconButton>
          </Grid>
          <Grid item xs={1} alignItems="center">
          </Grid>
          <Grid item xs={8} alignItems="center">
            <br />
            <Typography color="error">
              {error}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={3} style={{ right: '10px' }}>
          </Grid>
          <Grid item xs={1} ></Grid>
          <Grid item xs={10} >{!reset ? updatePassword : passWordCreationConfirm}</Grid>
          
          
          
        </Grid>
      </Paper>
    </Grid>
  </Grid>
  )

  
}
