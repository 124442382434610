import React, { useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@material-ui/core/Paper'
import { getChargePoint } from '../../service/chargepoints'
import TextField from '@material-ui/core/TextField'
import { Address } from '../../libs/types'
import { invite } from '../../service/users'
import { GmapsAutocomplete } from '../../components/gmaps'
import { useTranslation } from 'react-i18next'
import frFlag from '../../locales/fr/flag.png';
import enFlag from '../../locales/en/flag.png';
import frFlagGrey from '../../locales/fr/flag_grey.png';
import enFlagGrey from '../../locales/en/flag_grey.png';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { theme } from '../../libs/theme'
import { Email, LastName, Name, Username } from '../../components/authComponents'
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import bgF2m from '../../ressources/backgroundf2m.png';
import f2mlogo from '../../ressources/f2m_logo.png'
import Services from '../../service/services'
import { AuthContext } from '../../contexts/authContext'

const Loginformat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
const AccentRegex = /[À-ÿ]/

const useStyles = makeStyles({
  root: {
    height: '100vh',
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    fontWeight: 400,
    fontSize: '1rem',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  twoItem: {
    width: '100%',
    display: 'inline-block'
  },
  error: {
    display: 'inline-block',
    color: "red"
  },
  title: {
    fontSize: '2rem!important',
    color: theme.palette.primary.main
  },
  verticalCenter: {
    margin: 'auto',
  }
})

export function Register() {
  const classes = useStyles()

  const [deviceID, setdeviceID] = useState(new URLSearchParams(useLocation().search).get("deviceID"))
  const [userEmail, setuserEmail] = useState('')
  const [userLogin, setuserLogin] = useState('')
  const [userFirstName, setuserFirstName] = useState('')
  const [userLastName, setuserLastName] = useState('')
  const [userLanguage, setuserLanguage] = useState('fr')
  const [cpName, setcpName] = useState('')
  const [address, setAddress] = useState<Address | null>()
  const [error, setError] = useState('')
  const [isLoading, setisLoading] = useState(false)
  const [invited, setinvited] = useState(false)
  const [registrationCode, setRegistrationCode] = useState('')
  // const isValidRegistrationCode = 
  const isValidLogin = (!AccentRegex.test(userLogin) && !Loginformat.test(userLogin) && userLogin.length > 7)
  const isValidCPLabel = cpName.length > 0
  const isValidFirstName = userFirstName.length > 0
  const isValidLastName = userLastName.length > 0
  const isValidEmail = userEmail.length > 0
  const history = useHistory()
  const { t, i18n } = useTranslation();
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")


  const goToform = () => {
    history.push(`/signup?deviceID=${registrationCode}`)
    setdeviceID(registrationCode)

  }

  const inviteUser = async () => {

    try {
      setisLoading(true)
      var cpId
      try{
        cpId = await services.createChargePoint(address!, deviceID!, cpName)
      }catch(e:any){
        if (e.response &&  e.response.status == 409){
          setError(t("conflictDeviceError"))
          setisLoading(false)
          return
        }
        throw e
      }
     
      const cp = await getChargePoint(cpId)
     try{
      await invite(userLogin,
        userEmail,
        userFirstName,
        userLastName,
        cp!,
        userLanguage)
     }catch(e:any){
      if (e.response &&  e.response.status == 409){
        setError(t("conflictUserExist"))
        setisLoading(false)
        return
      }
      throw e
    }
      
      setisLoading(false)
      setError("")
      setinvited(true)
    } catch (e) {
      setisLoading(false)
      console.log(e)
      setError(t("registerError"))
    }
  }


  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };


  const form = (
    <>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={9} alignItems="center">
          <Typography className={classes.title}>{t("welcome")}</Typography>
        </Grid>
        <Grid item xs={3} style={{ right: '10px' }}>
          <IconButton onClick={e => { changeLanguage('fr'); setuserLanguage('fr') }} sx={{ p: 0 }}>
            <Avatar alt="Francais" src={frFlag} sx={{ height: '30px', width: '30px' }} />
          </IconButton>
          <IconButton onClick={e => { changeLanguage('en'); setuserLanguage('en') }} sx={{ p: 1 }}>
            <Avatar alt="English" src={enFlag} sx={{ height: '30px', width: '30px' }} />
          </IconButton>
        </Grid>

        <Grid item xs={12} alignItems="center">
          <br />
          <br />
          <Typography variant='h5'>{t("welcomeMessage")}</Typography>
        </Grid>

      </Grid>
      <Grid item xs={12} >
        <Box m={2}>
          <span className={classes.error}>
            {error ? error : " "}
          </span>
        </Box>
      </Grid>
      <Box display={'block'}>
        <Box mt={1}>
          <Grid container direction="row" spacing={1} justify="center">
            <Grid item xs={4} className={classes.verticalCenter}>
              <label className={classes.twoItem} >{t("cpName")} :</label>
            </Grid>
            <Grid item xs={8} className={classes.verticalCenter}>
              <TextField
                fullWidth
                className={classes.twoItem}
                variant="outlined"
                label={isValidCPLabel ? t("cpName") : t('mandatory')}
                error={!isValidCPLabel}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setcpName(evt.target.value)
                }}
              />
            </Grid>
            <Grid item xs={4} className={classes.verticalCenter}>
              <label className={classes.twoItem} >{t("address")}: </label>
            </Grid>
            <Grid item xs={8} className={classes.verticalCenter}>

              {GmapsAutocomplete(t("addressPlaceHolder"), (a: Address) => {
                setAddress(a)
              })}

            </Grid>
            <Grid item xs={4} className={classes.verticalCenter}>
              <label className={classes.twoItem} >{t("firstName")} : </label>
            </Grid>
            <Grid item xs={8} className={classes.verticalCenter}>
              <Name
                nameIsValid={isValidFirstName}
                setname={setuserFirstName}
              />
            </Grid>
            <Grid item xs={4} className={classes.verticalCenter}>
              <label className={classes.twoItem} >{t("lastName")} : </label>
            </Grid>
            <Grid item xs={8} className={classes.verticalCenter}>
              <LastName
                lastNameIsValid={isValidLastName}
                setLastName={setuserLastName}
              />
            </Grid>
            <Grid item xs={4} className={classes.verticalCenter}>
              <label className={classes.twoItem} >Login : </label>
            </Grid>
            <Grid item xs={8} className={classes.verticalCenter}>
              <Username
                initialValue={userLogin}
                usernameIsValid={isValidLogin}
                setUsername={setuserLogin}
              />
            </Grid>
            <Grid item xs={4} className={classes.verticalCenter}>
              <label className={classes.twoItem} >Email: </label>
            </Grid>
            <Grid item xs={8} className={classes.verticalCenter}>
              <Email
                emailIsValid={isValidEmail}
                setEmail={setuserEmail}
              />

            </Grid>
            <Grid item xs={4} className={classes.verticalCenter}>
              <label className={classes.twoItem} >{t('language')}: </label>
            </Grid>
            <Grid item xs={8} className={classes.verticalCenter}>
              <IconButton onClick={e => { setuserLanguage('fr') }} sx={{ p: 0 }}>
                <Avatar alt="Francais" src={userLanguage == 'fr' ? frFlag : frFlagGrey} sx={{ height: '30px', width: '30px' }} />
              </IconButton>
              <IconButton onClick={e => { setuserLanguage('en') }} sx={{ p: 1 }}>
                <Avatar alt="English" src={userLanguage == 'fr' ? enFlagGrey : enFlag} sx={{ height: '30px', width: '30px' }} />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Button style={{ display: !isLoading ? "block" : "none" }} disabled={!isValidLogin || !isValidEmail || !isValidCPLabel || !isValidFirstName || !isValidLastName} color="primary" variant="contained" onClick={inviteUser}>
                {t("signupConfirm")}
              </Button>

            </Grid>
          </Grid>
          <span className="loader" style={{ width: '50px', display: isLoading ? "block" : "none" }}></span>
        </Box>
      </Box>
    </>
  )

  const accountinvited = (
    <>

      <Box m={4}>
        <Typography variant='h5'>{t("confirmEmailSent")} </Typography>
      </Box>
      <Box m={4} style={{
        margin: 'auto',
        display: 'table'
      }}>
        <Typography variant='h6'>
          {t('checkemailaddress')}: <b>{userEmail}</b>
        </Typography>
      </Box>

    </>
  )
  if (!deviceID) {
    return (<Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <Box sx={{
        display: 'inline-flex',
        top: '20px',
        position: 'absolute',
        color: 'white'
      }}>
        <img
          src={f2mlogo}
          height={'90px'}
        />
        {/*<ElectricCarIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />*/}
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/#/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
        </Typography>
      </Box>
      <Grid xs={12} sm={8} lg={6} container direction="row" justify="center" alignItems="center" item>
        <Paper style={{ padding: 16, alignContent: "center" }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={9} alignItems="center">
              <Typography className={classes.title}>{t("welcome")}</Typography>
            </Grid>
            <Grid item xs={3} style={{ right: '10px' }}>
              <IconButton onClick={e => { changeLanguage('fr') }} sx={{ p: 0 }}>
                <Avatar alt="Francais" src={frFlag} sx={{ height: '30px', width: '30px' }} />
              </IconButton>
              <IconButton onClick={e => { changeLanguage('en') }} sx={{ p: 1 }}>
                <Avatar alt="English" src={enFlag} sx={{ height: '30px', width: '30px' }} />
              </IconButton>
            </Grid>
            <Grid item xs={12} alignItems="center">
              <br />
              <br />
              <Typography variant='h5'>{t("WelcomeCodeMessage")}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} >
            <Box m={2}>
              <span className={classes.error}>
                {error ? error : " "}
              </span>
            </Box>
          </Grid>
          <Box display={'block'}>
            <Box mt={1}>
              <Grid container direction="row" spacing={1} justify="center">
                <Grid item xs={8} className={classes.verticalCenter}>
                  <TextField
                    fullWidth
                    className={classes.twoItem}
                    variant="outlined"
                    label={t("code")}
                    //error={registrationCode.length > 0  && !isValidRegistrationCode}
                    onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setRegistrationCode(evt.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Box style={{ display: 'flex', height: '100%' }}>
                    <Button disabled={registrationCode.length == 0} onClick={goToform} variant="contained" color="primary">
                      {t("confirm")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <span className="loader" style={{ width: '50px', display: isLoading ? "block" : "none" }}></span>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>)
  }
  return (
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <Box sx={{
        display: 'inline-flex',
        top: '20px',
        position: 'absolute',
        color: 'white'
      }}>
        <ElectricCarIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/#/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
        </Typography>
      </Box>
      <Grid xs={12} sm={8} lg={6} container direction="row" justify="center" alignItems="center" item>
        <Paper style={{ padding: 16, alignContent: "center" }}>
          {!invited ? form : accountinvited}
        </Paper>
      </Grid>
    </Grid>
  )
}
